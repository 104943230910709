
<script setup lang="ts">
    import { inject, computed } from "vue";

    export interface IProps {
        text?: string;
        icon?: string;
        target?: Function;
        disabled?: boolean;
        href?: string;
    };

    const props = defineProps<IProps>();
    const model = inject("action-sheet");

    function onClick() {
        model.value = false;
    }
</script>

<template>
    <slot />
    <a @click="onClick" :href="href" :ref="target" class="afm2-action-sheet-btn" :class="{ 'disabled': disabled }" v-bind="$attrs">
        <div class="flex-grow-1 text-truncate" :class="{ 'text-center': icon == null }">
            <slot>
                <span>
                    {{ text ?? "" }}
                </span>
            </slot>
        </div>
        <template v-if="icon">
            <div class="flex-shrink-0 d-flex justify-content-end">
                <i class="fa-fw" :class="icon" v-if="icon.startsWith('fa')" />
                <i class="" :class="icon" v-if="icon.startsWith('bi')" />
            </div>
        </template>
    </a>
</template>

<style scoped>
    .afm2-action-sheet-btn {
        display: flex;
        justify-content: center;
        gap: 1rem;

        >>> i {
            font-size: 1.25em;
        }

        &.disabled {
            pointer-events: none;
            cursor: default;
            background-color: var(--bs-gray-100);
            color: var(--bs-gray-500);
            pointer-events: none;
        }
    }
</style>
